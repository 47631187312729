
export const reqGetListReferral = async(
  axios, {
    page,
    perpage,
    q,
    orderBy,
    order,
  }) => {
  return axios.get('/v1/referral/list-referral', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null,
      orderBy: orderBy || null, // total_poin | total_referral
      order: order || null, // asc | desc
    },
  })
}

export const reqGetListReferralGifts = async(axios, { page, perpage, q }) => {
  return axios.get('/v1/referral/gifts', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null,
    },
  })
}

export const reqGetReferral = async(axios) => {
  // ref reqGetListReferral: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/350
  return axios.get('/v1/referral/')
}

export const reqMenuRecommendation_MealPlanDetail = async(axios, { clientId, programId, month, mealTime }) => {
  return axios.get('/v1/nutritionist/meal-plans/menu-recommendation/meal-plan-detail', {
    params: {
      clientId: clientId || null,
      programId: programId || null,
      month: month || null,
      mealTime: mealTime || null,
    },
  })
}

export const reqMenuRecommendation_PortionUnit = async(axios) => {
  return axios.get('/v1/nutritionist/meal-plans/menu-recommendation/portion-unit')
}

export const reqMenuRecommendation_GetDraft = async(axios, { clientId, programId, month, day }) => {
  return axios.get('/v1/nutritionist/meal-plans/menu-recommendation/data', {
    params: {
      clientId: clientId || null,
      programId: programId || null,
      month: month || null,
      day: day || null,
    },
  })

}

export const reqMenuRecommendation_Create = async(axios, data) => {
  // const data = {
  //   isDraft: null,                 // 0 | 1
  //   isSaveDraf: null,              // 0 | 1
  //   clientId: null,
  //   programId: null,
  //   month: null,                   // {base on meal plan month}
  //   day: null,                     // {1…10}
  //   validation: null,              // {-1 | 1}
  //   breakFast: {
  //     validation: null,            // {-1 | 1}
  //     duplicationDetail: {
  //       id: null,
  //       parentDays: null,
  //       isEdited: null,            // 0 | 1
  //     },
  //     startTime: null,
  //     endTime: null,
  //     caloriesNeedPercent: null,
  //     caloriesAmount: null,
  //     dataMenu: [                  // { array }
  //       {
  //         menuType: null,          // single | multiple
  //         menuName: null,          // {multipleEntry Only}
  //         menus: {                 // {object: singleEntry | array: multipleEntry}
  //           foodName: null,
  //           servingSize: null,
  //           servingSizeUnit: null,
  //           photoUrl: null,
  //         },
  //       },
  //       // ...
  //     ],
  //
  //   },
  //   morningSnack: {
  //                                  // same as breakFast
  //   },
  //   lunch: {
  //                                  // same as breakFast
  //   },
  //   afternoonSnack: {
  //                                  // same as breakFast
  //   },
  //   dinner: {
  //                                  // same as breakFast
  //   },
  //   nightSnack: {
  //                                  // same as breakFast
  //   },
  // }
  return axios.post('/v1/nutritionist/meal-plans/menu-recommendation', data)
}

export const reqMenuMealPlan_TriggerForBE_1 = async(axios, { clientId, programId, month }) => {
  return axios.get('/v1/nutritionist/meal-plans/month', {
    params: {
      clientId: clientId || null,
      programId: programId || null,
      month: month || null,
    },
  })
}

export const reqMenuMealPlan_TriggerForBE_2 = async(axios, { clientId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/menu-recommendation/list/${clientId}`)
}
